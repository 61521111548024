import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaTwitterSquare, FaInstagramSquare } from "react-icons/fa";

const social_media = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/',
    icon: <FaFacebookSquare/>
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/',
    icon: <FaTwitterSquare/>
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/',
    icon: <FaInstagramSquare/>
  }
];

const Footer = () => {
  return (
    <footer className="flex justify-between items-center bg-secondary-300 text-sm text-white">
      <div className="m-3">
        MeRent SA de CV <br/>
        +52 1 81 1660 5169 
      </div>
      <div className="m-3 flex justify-between">
        {
          social_media.map((item) => {
            return <a key={item.name} title={item.name} href={item.link} className="text-white mx-2 text-2xl hover:text-primary-300 hover:font-bold" target="_blank">{item.icon}</a>
          })
        }
      </div>
      <div className="m-3">
        <Link to="/contacto" className="text-white hover:text-primary-300 visited:text-white selection:text-white">
          Contacto
        </Link><br/>
        <Link to="/terminos-condiciones" className="text-white hover:text-primary-300 visited:text-white selection:text-white">
          Terminos y condiciones
        </Link><br/>
        <Link to="/aviso-privacidad" className="text-white hover:text-primary-300 visited:text-white selection:text-white">
          Aviso de privacidad
        </Link>
      </div>
    </footer>
  )
}

export default Footer;
