import React from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import { Link } from "react-router-dom";
import Image from "../components/UI/LazyLoadImage";
import appstore from "../images/appstore.png";
import playstore from "../images/playstore_1.png";
import merent_logo from "../images/merent-logo.png";

const AvisoPrivacidad = () => (
  <Layout>
    <Container> 
      <div className="p-5 pb-12 text-lg rounded-xl bg-white shadow-lg">
        <div className="text-center font-semibold text-3xl text-primary-500">
          <Image
          src={merent_logo}
          width={350}
          alt="MERENT logo"
        />
        </div>
        <p>

          <h1 className="text-center">AVISO DE PRIVACIDAD</h1> 

          <h4>RESPONSABLE DEL TRATAMIENTO DE LOS DATOS PERSONALES.</h4>
          De conformidad por lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo, la “Ley” o “LFPDPPP”) y demás disposiciones aplicables, Me R Soluciones Digitales de Arrendamiento, S.A.P.I. DE C.V. (“MeRent”) con domicilio en Calle Enrique Rébsamen 626, Núm. Int. 201, Col. Narvarte Poniente, Benito Juárez, CP. 03020 busca brindar protección sobre los datos proporcionados por los Usuarios (en adelante, el “Titular” o los “Titulares”).
          <h4>DATOS QUE SE RECABAN Y TRATAMIENTO</h4>
          En relación con los datos personales que la Plataforma MeRent App solicita a efecto de que los Usuarios tengan acceso a dicha Plataforma, MeRent manifiesta que cuenta con las medidas de seguridad físicas, técnicas y administrativas para proteger sus datos personales de pérdida, mal uso, alteración o destrucción. Los proveedores de servicios, personal, socios comerciales, agentes y empleados de MeRent están obligados contractualmente a mantener la confidencialidad de dichos datos.
          <h4>EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN CANCELACIÓN Y OPOSICIÓN (DERECHOS ARCO).</h4>
          Derivado de lo establecido en la Ley, el Titular podrá ejercer en cualquier momento los derechos de Acceso, Rectificación, Cancelación y Oposición (Derechos ARCO) con los cuales podrá acceder a su información personal que posee la Plataforma MeRent App y al tratamiento de los mismos en caso de que el Titular considere que no se requieren para alguna de las finalidades señaladas en el presente Aviso de Privacidad, contactando a MeRent en su domicilio o iniciando el trámite al correo electrónico: contacto@merent.mx o al teléfono (52) 55 9402 1264 en un horario comprendido de 9 a 5 pm en días hábiles.
          <h4>MODIFICACIONES AL AVISO DE PRIVACIDAD.</h4>
          MeRent se reserva el derecho de efectuar en cualquier momento modificaciones con el objetivo de actualizar el presente Aviso de Privacidad para reflejar los cambios que se produzcan en nuestros servicios, prácticas de privacidad, así como para atender las novedades legislativas o jurisprudenciales. En tal caso, las modificaciones estarán disponibles en el Sitio Web de la Plataforma MeRent <a target="_blank" href="https://merent.mx">https://merent.mx</a>, por lo que es responsabilidad del Titular revisar periódicamente el contenido del Aviso de Privacidad.
        </p>
      </div>
    </Container>
  </Layout>
)


export default AvisoPrivacidad;
