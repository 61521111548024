import React from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import { Link } from "react-router-dom";
import Image from "../components/UI/LazyLoadImage";
import appstore from "../images/appstore.png";
import playstore from "../images/playstore_1.png";
import merent_logo from "../images/merent-logo.png";

const TerminosCondiciones = () => (
  <Layout>
    <Container> 
      <div className="p-5 pb-12 text-lg rounded-xl bg-white shadow-lg">
        <div className="text-center font-semibold text-3xl text-primary-500">
          <Image
          src={merent_logo}
          width={350}
          alt="MERENT logo"
        />
        </div>
        <p>

          <h1 className="text-center">Terminos y condiciones de uso de la plataforma</h1>
            A los que se sujeta el uso de la presente plataforma digital identificada como “MeRent”, así como de cualesquiera subdominios, interfaces y páginas referenciadas a ella (indistintamente esta "Aplicación" o el “Sitio”, según el contexto lo requiere, incluyendo sin limitar la "Plataforma MeRent" o la “Plataforma”). 
          <h4 className="font-bold">1. Advertencia sobre expresiones definidas.</h4>
            A lo largo del presente documento se utilizan diversas expresiones escritas en “Entrecomillado”, MAYÚSCULAS o con Mayúsculas Iniciales, ya sea en plural o singular, que definen un concepto, idea, proceso u otros términos, y que se utilizan para facilitar la lectura del mismo. La connotación que se atribuye a algunas de esas expresiones puede desprenderse de la propia lectura del documento, al aparecer en algunos casos entrecomilladas y/o entre paréntesis, aún y cuando posteriormente no se utilicen entrecomilladas. Para precisar su definición y facilitar su posterior consulta, en la parte final de este documento se incluye un glosario con las definiciones aplicables.
          <h4 className="font-bold">2. Implicaciones de alta y Firma Autógrafa Digitalizada o la Firma Electrónica Avanzada.</h4>
            El alta como Usuario en la Plataforma MeRent implica la manifestación expresa de la voluntad del propio Usuario para producir las consecuencias jurídicas que se describen en este instrumento respecto de cada tipo de alta, incluyendo la creación de la clave o contraseña secreta por parte del mismo Usuario, como persona física, o de quien lo represente en caso de que el Usuario sea una persona moral (el Usuario persona física o el representante de un Usuario que sea persona moral, será referido indistintamente para estos efectos como "Firmante").
          <br/><br/>
            La creación de la clave o contraseña secreta por parte del Firmante, así como su ingreso posterior a la Plataforma MeRent, utilizando dicha clave o contraseña, presupone la utilización de la “Firma Autógrafa Digitalizada o la Firma Electrónica Avanzada" del propio Firmante cuando se realicen acciones dentro de la citada plataforma, surtiendo los mismos efectos de su consentimiento y firma autógrafa y sujetando al Usuario a la relación jurídica de que se trate. Estas implicaciones legales y otras que se describen más adelante en este documento respecto de cada tipo de alta, son de especial relevancia para los Usuarios, por lo que antes de darse de alta, se recomienda la lectura completa a detalle y asesorada del presente documento, así como de los demás documentos relacionados a los que podrá quedar vinculado un Usuario por la utilización de la Firma Autógrafa Digitalizada o la Firma Electrónica Avanzada.
          <h4 className="font-bold">3. Descripción general de la Plataforma.</h4>
          La Plataforma ha sido concebida como un espacio donde convergen tanto personas, ya sean físicas o morales, que requieran DISPONER de todo tipo de maquinaria y/o equipo de diversas industrias (la “Maquinaria”), así como personas físicas o morales, que pueden válidamente poner a DISPOSICION Maquinaria, además de (i) personas dedicadas a la operación de Maquinaria o (ii) personas físicas o morales dedicadas a brindar servicio mecánico a la Maquinaria; quienes podrán ofrecer sus servicios al efecto. Unos y otros pueden darse de alta en la Plataforma MeRent. 
          <br/><br/>
          Las personas interesadas en DISPONER de Maquinaria deberán darse de alta como "Usuario-Solicitante"; los interesados en poner a Disposición Maquinaria, deberán darse de alta como “Usuarios-Propietarios"; los operadores interesados en ofrecer sus servicios de operación a la Maquinaria, deberán darse de alta como “Usuarios-Operadores”; y las personas físicas o morales dedicadas a brindar servicio mecánico a la Maquinaria, deberán darse de alta como “Usuarios Mecánicos”; (indistintamente, los "Usuarios").
          <br/><br/>
          Cualquier persona puede ingresar como "Visitante" a la Plataforma MeRent, con acceso a la información general del mismo, incluyendo al presente documento de Términos y Condiciones de Uso de la Plataforma, con la finalidad de que pueda valorar y, en su caso, decidir respecto de su alta como Usuario. Únicamente los Visitantes que se den de alta como Usuarios tendrán acceso a la Plataforma MeRent y en consecuencia, hayan aceptado de forma expresa a través su Firma Autógrafa Digitalizada o la Firma Electrónica Avanzada, los presentes Términos y Condiciones de Uso de la Plataforma MeRent. 
          <h4 className="font-bold">4. De los Servicios Ofrecidos por la Plataforma MeRent.</h4>
          <ul className="list-decimal ml-12">
            <li>Renta Abierta</li>
            <li>Renta Segura</li>
            <li>Listado de Operadores</li>
            <li>Listado de Mecánicos</li>
          </ul>
          La mecánica de operación para LA DISPOSICION de Maquinaria por parte de los Usuarios y/o la oferta de los servicios de operación y/o mecánicos de Maquinaria, se regirá, según sea el caso, conforme a lo siguiente:
          <h4 className="font-bold">5. Renta Abierta.</h4>
          <ul className="list-decimal ml-12">
            <li className="marker:font-extrabold">Se refiere única y exclusivamente a la oferta dentro de la Plataforma MeRent de Maquinaria que ha sido ingresada conforme a lo siguiente.</li>
            <li className="marker:font-extrabold">El Usuario-Propietario deberá aceptar los Términos y Condiciones para el uso de la Plataforma MeRent.</li>
            <li className="marker:font-extrabold">El Usuario-Propietario deberá pagar una contraprestación ya sea mensual, trimestral, semestral o anual a la Plataforma MeRent, por la cantidad que al efecto se establezca, para que pueda poner a DISPOSICION su Maquinaria dentro de la citada Plataforma por el plazo estipulado, bajo el esquema de Renta Abierta, misma que podrá ser consultada por el Usuario-Solicitante. (la “Contraprestación Renta Abierta”).</li>
            <li className="marker:font-extrabold">El pago de la Contraprestación Renta Abierta permitirá al Usuario-Propietario subir al catálogo de la Plataforma MeRent bajo el esquema de Renta Abierta, la Maquinaria que acredite válidamente para ponerla a DISPOSICIÒN. (el “Catálogo de Renta Abierta”).</li>
            <li className="marker:font-extrabold">De cada Maquinaria que se suba al Catálogo de Renta Abierta se deberá especificar lo siguiente:</li>
            <ul className="ml-12">
              <li className="marker:font-extrabold">Datos generales de identificación</li>
              <li className="marker:font-extrabold">Características </li>
              <li className="marker:font-extrabold">Fotografías</li>
              <li className="marker:font-extrabold">Horómetros</li>
            </ul>
            <li className="marker:font-extrabold">Importe de disposición en sus diferentes periodos.</li>
            <li className="marker:font-extrabold">Cualquier Visitante de la Plataforma MeRent, podrá acceder al Catálogo de Renta Abierta.</li>
            <li className="marker:font-extrabold">Para tener acceso a los datos de contacto de los Usuarios-Propietarios de la Maquinaria del Catálogo de Renta Abierta, el Visitante deberá registrarse como Usuario-Solicitante, ingresando los datos solicitados al efecto, y aceptar los Términos y Condiciones para el uso de la Plataforma MeRent, en tal carácter.</li>
            <li className="marker:font-extrabold">Una vez que ha concluido su registro como Usuario-Solicitante, podrá obtener los datos de contacto de todos los Usuarios-Propietarios de Maquinaria enlistados bajo este esquema.</li>
            <li className="marker:font-extrabold">Bajo el esquema de Renta Abierta, una vez que los Usuario-Solicitantes y Usuarios-Propietarios entren en contacto conforme el proceso establecido en la Plataforma MeRent, podrán llevar a cabo una operación de arrendamiento de manera independiente, dando por concluida desde ese momento la responsabilidad de MeRent, respecto de dicha operación.</li>
          </ul>
          <h4 className="font-bold">6. Renta Segura.</h4>
          <ul className="list-decimal  ml-12">
            <li className="marker:font-extrabold">Se refiere a las operaciones en las que el Usuario-Propietario encomienda a MeRent llevar a cabo todas las acciones y trámites necesarios para promover, administrar y poner a DISPOCISION su Maquinaria.</li>
            <li className="marker:font-extrabold">El Usuario-Propietario, deberá darse de alta en la Plataforma MeRent, en tal carácter, ingresando los datos solicitados al efecto y deberá aceptar los Términos y Condiciones para el uso de la Plataforma. </li>
            <li className="marker:font-extrabold">Una vez que ha concluido su registro en la Plataforma, se le permitirá subir al catálogo de la Plataforma MeRent bajo el esquema de Renta Segura, la Maquinaria que acredite válidamente para poder ponerla a DISPOSICION. (el “Catálogo de Renta Segura”).</li>
            <li className="marker:font-extrabold">De cada Maquinaria que se suba al Catálogo de Renta Segura, deberá especificar lo siguiente:</li>
            <ul className="ml-12">
              <li className="marker:font-extrabold">Importe (conforme a lo establecido más adelante)</li>
              <li className="marker:font-extrabold">Datos generales de identificación</li>
              <li className="marker:font-extrabold">Características </li>
              <li className="marker:font-extrabold">Fotografías</li>
              <li className="marker:font-extrabold">Horómetros</li>
            </ul>
            <li className="marker:font-extrabold">Importe por DISPOSICION : Los Usuarios-Propietarios determinarán el importe de la DISPOSICION de la Maquinaria por cada periodo publicado (pudiendo establecerse enunciativa, más no limitativamente como diario, semanal, quincenal o mensual), importe que deberá ser aprobado por MeRent, previo a su publicación,  a efecto de que se encuentre dentro de los parámetros establecidos por el mercado para la Maquinaria de que se trate (la “Disposicion”). </li>
            <li className="marker:font-extrabold">Se deberá considerar en el importe de la Disposición publicado el mantenimiento de servicio preventivo de acuerdo al manual de operación del equipo y la restitución de elementos de desgaste como mangueras y sellos.</li>
            <li className="marker:font-extrabold">Los Usuarios-Propietarios, se obligan a respetar el monto de la DISPOSICION publicada, de lo contrario MeRent estará facultado para ofrecer al Usuario-Solicitante interesado otra Maquinaria del Catálogo de Renta Segura, con características similares.</li>
            <li className="marker:font-extrabold">Contrato de Comisión Mercantil: Los Usuarios-Propietarios, deberán celebrar un contrato de comisión mercantil con ME R SOLUCIONES DIGITALES DE ARRENDAMIENTO, S.A.P.I. DE C.V. (“MeRent”), a efecto de que MeRent, en nombre y representación de los Usuarios-Propietarios, bajo el esquema de Renta Segura, lleve a cabo los actos y gestiones establecidos en dicho contrato, cuyo texto se encuentra disponible en todo momento en el Sitio (el “Contrato de Comisión Mercantil”).</li>
            <li className="marker:font-extrabold">Representación: MeRent se compromete a actuar siempre en nombre y en representación del Usuarios-Propietarios y jamás por cuenta propia.</li>
            <li className="marker:font-extrabold">Autorización de la Disposición: En cuanto MeRent tenga una propuesta de Disposición de Maquinaria la hará del conocimiento del Usuario-Propietario, para que éste autorice su celebración, en los términos propuestos.</li>
            <li className="marker:font-extrabold">Comisión. El Usuario-Propietario pagará a MeRent la comisión establecida, una vez que se celebre un contrato de disposición de maquinaria bajo el esquema de Renta Segura (la “Comisión”). Para efectos del cobro de la Comisión, una vez que MeRent reciba el pago de la Disposición, éste descontará la parte que le corresponda por concepto de Comisión. La cantidad restante se depositará en la cuenta del Usuario-Arrendador, conforme a lo siguiente:</li>
            <li className="marker:font-extrabold">Contrato de DISPOSICION: Se deberá celebrar un contrato de DISPOSICION entre el Usuario-Propietario y el Usuario-Solicitante.  Cuyo Modelo se encuentra disponible en todo momento en el Sitio (el “Contrato de Disposición”)</li>
            <li className="marker:font-extrabold">Deposito en Garantía: En el Contrato de DISPOSICION se establece la autorización para que MeRent, que una vez descontada la Comisión, retenga la cantidad equivalente al 20% (veinte por ciento) del importe total de la DISPOSICION, como Deposito en Garantía para cubrir: (i) las reparaciones o sustituciones de elementos de desgaste que no hayan sido atendidos por el Usuario-Propietario, en un periodo máximo de 36 (treinta y seis) horas previa notificación por parte MeRent; (ii) el incumplimiento en el pago de honorarios al operador de ser el caso; (iii) para reembolsar al Usuario-Solicitante los días de renta que no hayan podido ser trabajados por fallas mecánicas que requieran trabajos mayores en tiempos fuera de lo ordinario; (iv) para el pago de fletes extraordinarios para el remplazo de Maquinaria averiada. En todos los casos anteriores el Usuario-Propietario será notificado de manera oficial para que pueda atenderlos en los períodos establecidos, en caso contrario MeRent estará autorizado a intervenir haciendo uso del depósito en Garantía y cobrará adicionalmente una  comisión por las gestiones equivalente al 30% (treinta por ciento) del monto total que resulte de los trabajos o gestiones realizados. </li>
            <li className="marker:font-extrabold">Los importes por las gestiones o trabajos realizados se cubrirán hasta lo que alcance con el Depósito en Garantía. En caso de no existir eventualidades que requieran ser cubiertas con el Depósito en Garantía, este será entregado al Usuario-Propietario, al término de la vigencia del Contrato de Arrendamiento.</li>
            <li className="marker:font-extrabold">Con el Depósito en Garantía se cubrirán las reparaciones en el siguiente orden de prelación: (i) sustitución de mangueras en mal estado o que hayan cumplido su vida útil; (ii) sustitución de sellos en mal estado o que hayan cumplido su vida útil; (iii) sustitución de baterías en mal estado o que hayan cumplido su vida útil; y (iv) averías generales de los equipos propios de su uso.</li>
            <li className="marker:font-extrabold">Transportación de la Maquinaria: El Usuario-Propietario permitirá que MeRent, contrate a las personas físicas y/o morales necesarias para que pueda llevar a cabo la transportación de la Maquinaria al lugar requerido por el Usuario-Solicitante, así como su regreso al lugar de origen.</li>
            <li className="marker:font-extrabold">Cualquier Visitante de la Plataforma MeRent, podrá acceder al Catálogo de Renta Segura</li>
            <li className="marker:font-extrabold">para poder DISPONER de Maquinaria bajo el esquema de Renta Segura, el Visitante deberá registrarse como Usuario-Solicitante, ingresando los datos solicitados al efecto, y aceptar los Términos y Condiciones para el uso de la Plataforma MeRent, en tal carácter.</li>
            <li className="marker:font-extrabold">Una vez que ha concluido su registro como Usuario-Solicitante, podrá elegir dentro del Catálogo de Renta Segura la Maquinaria que le interese bajo el referido esquema, generando una notificación a MeRent, a través de la Plataforma.</li>
          </ul>
          <h4 className="font-bold">7. Catálogo de Operadores.</h4>
          <ul className="list-decimal ml-12">
            <li className="marker:font-extrabold">Se refiere única y exclusivamente a la oferta de servicios por parte de personas físicas que brinden sus servicios para operar la Maquinaria (los “Usuarios-Operadores”), a través de la Plataforma MeRent.</li>
            <li className="marker:font-extrabold">El Usuario-Operador deberá aceptar los Términos y Condiciones para el uso de la Plataforma MeRent.</li>
            <li className="marker:font-extrabold">El Usuario-Operador deberá pagar una contraprestación ya sea mensual, trimestral, semestral o anual a la Plataforma MeRent, por la cantidad que al efecto se establezca, para que pueda ofrecer sus servicios dentro de la citada Plataforma. (la “Contraprestación Usuario-Operador”).</li>
            <li className="marker:font-extrabold">El pago de la Contraprestación Usuario-Operador, le permitirá aparecer en el catálogo de la Plataforma MeRent para ofrecer sus servicios. (el “Catálogo de Operadores”).</li>
            <li className="marker:font-extrabold">El Usuario-Operador deberá proporcionar los siguientes datos:</li>
            <li className="marker:font-extrabold">Sus datos generales de identificación</li>
            <li className="marker:font-extrabold">Deberá detallar la Maquinaria que puede operar.</li>
            <li className="marker:font-extrabold">Cualquier Visitante de la Plataforma MeRent, podrá acceder al Catálogo de Operadores.</li>
            <li className="marker:font-extrabold">Para tener acceso a los datos de contacto de los Usuarios-Operadores, el Visitante deberá registrarse como Usuario-Solicitante, ingresando los datos solicitados al efecto, y aceptar los Términos y Condiciones para el uso de la Plataforma MeRent, en tal carácter.</li>
            <li className="marker:font-extrabold">Una vez que ha concluido su registro como Usuario-Solicitante, podrá obtener los datos de contacto de todos los Usuarios-Operadores de Maquinaria que integran el mencionado Catálogo.</li>
            <li className="marker:font-extrabold">Una vez que los Usuarios Solicitantes y Operador entren en contacto conforme el proceso establecido en la Plataforma MeRent, llevarán a cabo las operaciones de prestación de servicios de manera independiente, dando por concluida desde ese momento la responsabilidad de MeRent, respecto de dichas operaciones.</li>
          </ul>
          <h4 className="font-bold">8. Catálogo de Mecánicos.</h4>
          <ul className="list-decimal ml-12">
            <li className="marker:font-extrabold">Se refiere única y exclusivamente a la oferta de servicios por parte de personas físicas y/o morales que brinden sus servicios mecánicos para la Maquinaria (los “Usuarios-Mecánicos”), a través de la Plataforma MeRent.</li>
            <li className="marker:font-extrabold">El Usuario-Mecánico deberá aceptar los Términos y Condiciones para el uso de la Plataforma MeRent.</li>
            <li className="marker:font-extrabold">El Usuario-Mecánico deberá pagar una contraprestación ya sea mensual, trimestral, semestral o anual a la Plataforma MeRent, por la cantidad que al efecto se establezca, para que pueda ofrecer sus servicios dentro de la citada Plataforma. (la “Contraprestación Usuario-Mecánico”).</li>
            <li className="marker:font-extrabold">El pago de la Contraprestación Usuario-Mecánico, le permitirá aparecer en el catálogo de la Plataforma MeRent para ofrecer sus servicios. (el “Catálogo de Mecánicos”).</li>
            <li className="marker:font-extrabold">El Usuario-Mecánico deberá proporcionar los siguientes datos:</li>
            <li className="marker:font-extrabold">Sus datos generales de identificación</li>
            <li className="marker:font-extrabold">Deberá detallar la Maquinaria a la que puede dar servicio mecánico</li>
            <li className="marker:font-extrabold">Cualquier Visitante de la Plataforma MeRent, podrá acceder al Catálogo de Mecánicos.</li>
            <li className="marker:font-extrabold">Para tener acceso a los datos de contacto de los Usuarios-Mecánicos, el Visitante deberá registrarse como Usuario-Solicitante, ingresando los datos solicitados al efecto, y aceptar los Términos y Condiciones para el uso de la Plataforma MeRent, en tal carácter.</li>
            <li className="marker:font-extrabold">Una vez que ha concluido su registro como Usuario-Solicitante, podrá obtener los datos de contacto de todos los Usuarios-Mecánicos de Maquinaria que integran el mencionado Catálogo.</li>
            <li className="marker:font-extrabold">Una vez que los Usuario-Solicitante y Mecánico entren en contacto conforme el proceso establecido en la Plataforma MeRent, llevarán a cabo las operaciones de prestación de servicios de manera independiente, dando por concluida desde ese momento la responsabilidad de MeRent, respecto de dichas operaciones.</li>
          </ul>
          <h4 className="font-bold">9. Evaluaciones.</h4>
          <ul className="ml-12">
          <li><b>9.1</b> Después de cada Arrendamiento y/o Prestación de Servicios derivado del uso de la Plataforma MeRent, los Usuarios tendrán la oportunidad de evaluarse mutuamente. Su Evaluación debe ser precisa y no puede contener ningún lenguaje discriminatorio, ofensivo, difamatorio u otro que infrinja lo siguiente: </li>
          <li><b>9.2</b> Las evaluaciones deben ser imparciales:</li>
          <li className="ml-4"><b>9.2.1</b> Los Usuarios de la Plataforma MeRent, no pueden coaccionar, intimidar, extorsionar, amenazar, incentivar o manipular a otras personas con el objetivo de influir en una evaluación, por ejemplo, prometiendo una compensación a cambio de un comentario positivo o amenazando con represalias si es negativo.</li>
          <li className="ml-4"><b>9.2.2</b> Las evaluaciones no podrán ofrecerse ni retenerse a cambio de algo de valor, como un descuento, un reembolso, una evaluación recíproca o la promesa de no tomar represalias. Tampoco se pueden utilizar para tratar de engañar o inducir a error a MeRent ni a ninguna otra persona.</li>
          <li><b>9.3</b> Las evaluaciones deben ser pertinentes:</li>
          <li className="ml-4"><b>9.3.1</b> El objetivo es que proporcionen información relevante sobre la disposición de Maquinaria o la prestación de servicios. Una evaluación debe ayudar a otros Usuarios a tomar decisiones.</li>
          <li className="ml-4"><b>9.3.2</b> Las evaluaciones no pueden incluir contenido explícito, discriminatorio, dañino, fraudulento o ilegal.</li>
          <li><b>9.4</b> Denunciar evaluaciones: Si una evaluación incumple los lineamientos anteriores, se podría eliminar, incluida la valoración que la acompaña y otros contenidos relacionados.</li>
          <li><b>9.5</b> MeRent no verifica la exactitud de las Evaluaciones, que podrían ser incorrectas o engañosas.</li>
          </ul>
          <h4 className="font-bold">10. Independencia entre los Usuarios y MeRent.</h4>
          <ul className="ml-12">
          <li><b>10.1</b> Los presentes Términos y Condiciones y/o las relaciones que pudieran derivarse del Uso de la Plataforma MeRent, no constituyen una relación laboral, asociación, sociedad, coinversión o acuerdo similar entre los Usuarios y/o MeRent y/o cualquier tercero subcontratado, por lo que se consideran independientes.  Cada uno de los Usuarios y/o MeRent y/o cualquier tercero subcontratado, no es, ni debe actuar, como agente, mandatario o representante del otro. Asimismo, no tienen el poder ni la facultad para obligar o comprometer a cualesquiera otro en forma alguna frente a terceros. Los terceros subcontratados, en caso de existir, son, y en todo tiempo deben ser, contratistas independientes en todos los asuntos relacionados con los presentes Términos y Condiciones y las relaciones que pudieran derivarse del Uso de la Plataforma MeRent, por tanto, de ninguna manera MeRent ni sus subcontratistas, empleados, directores o dependientes son empleados de los Usuarios. Ni los Usuarios o sus subcontratistas o empleados, se ostentarán como empleados de MeRent, ni darán razón a persona alguna que permita que se crea que es empleado de este último.</li>
          <li><b>10.2</b> Por tanto los Usuarios y/o MeRent y/o cualquier tercero subcontratado, convienen que indemnizarán y sacarán en paz y a salvo a cualesquiera de ellos, que sea demandada por algún empleado de algún  Usuario y/o MeRent y/o cualquier tercero subcontratado, y a sus accionistas, funcionarios, consejeros y empleados, de cualquier demanda, denuncia o acción de carácter civil, mercantil, penal, laboral, administrativa o fiscal, procedimiento, pérdida, daño, obligación y/o responsabilidad interpuesto en contra cualquiera de alguno de ellos, sus empleados, directores, dependientes o empleados, por cualquier persona física o moral, o cualquier entidad pública o privada, con motivo de los presentes Términos y Condiciones y/o las relaciones que pudieran derivarse del Uso de la Plataforma MeRent, y pagará a la afectada todos los gastos y costas de juicio relativos, en su caso, así como honorarios razonables de abogados.</li>
          </ul>
          <h4 className="font-bold">11. Responsabilidad Laboral.</h4>
          <ul className="ml-12">
          <li><b>11.1</b> Queda expresamente convenido, que para el caso de que alguno de los Usuarios y/o MeRent y/o cualquier tercero subcontratado, llegase a ser demandada laboralmente por personal de cualesquiera de los Usuarios y/o MeRent y/o cualquier tercero subcontratado por ellas, por cualquier causa, alegándose que alguno, que no le corresponda, es patrón, patrón solidario o patrón substituto del demandante, bien sea en juicios individuales u otro procedimiento contencioso de naturaleza laboral, quien haya empleado a esa persona o al subcontratista que la haya empleado se obliga a sacar en paz y a salvo los derechos de los Usuarios y/o MeRent y/o cualquier tercero subcontratado, de estos procedimientos, incluyendo sin limitar reclamaciones, demandas, acciones, deudas, responsabilidades, indemnizaciones, juicios, costos, y honorarios de abogados provenientes de reclamaciones por cuenta de, o de cualquier forma de pérdida de, o daño a la propiedad de, las lesiones de, o la muerte de, cualquiera del personal suministrado, apoyándose con abogados especializados en la materia de que se trate y a cubrir sus honorarios, así como lo reclamado en caso de ser necesario.</li>
          <li><b>11.2</b> Todo lo expuesto en este apartado se hace extensivo respecto de cualquier otra responsabilidad legal frente al IMSS, INFONAVIT, de carácter fiscal, de seguridad social, o de cualquier naturaleza, con cargo a los Usuarios y/o MeRent y/o cualquier tercero subcontratado responsable o subcontratista y derivado de su relación laboral con el personal que se le haya proporcionado servicios relacionados con el uso de la Plataforma MeRent.</li>
          <li><b>11.3</b> Si el Usuario y/o MeRent y/o cualquier tercero subcontratado, resulta afectado en los términos antes referidos, se obliga a notificar por escrito al Usuario y/o MeRent y/o cualquier tercero subcontratado, en forma inmediata de la notificación judicial o extrajudicial por medio de la cual tenga conocimiento de cualquier procedimiento relacionado con las reclamaciones citadas, a fin de que pueda oponer la mejor defensa procedente, así mismo se obliga a otorgar a los profesionales del derecho que éste designe carta poder acompañada de copia certificada del testimonio notarial de quien la otorga, a fin de que dichos profesionales puedan comparecer en defensa del afectado.</li>
          <li><b>11.4</b> En caso de que el afectado opte por que sus propios abogados comparezcan en su defensa deberá comunicarlo también por escrito al Usuario y/o MeRent y/o cualquier tercero subcontratado, por lo cualesquiera de éstos últimos, no será responsable de las consecuencias de las actuaciones o falta de actuación de dichos abogados, así como tampoco será responsable del pago de los honorarios que dichos abogados generen.</li>
          <li><b>11.5</b> Lo establecido en el párrafo anterior, no impide que en cualquier caso los abogados del afectado puedan solicitar de manera escrita a los abogados del Usuario y/o MeRent y/o cualquier tercero subcontratado, informes detallados del avance de los asuntos, así como de las estrategias a seguir para la mejor resolución de los mismos.</li>
          </ul>
          <h4 className="font-bold">12. Confidencialidad.</h4>
          <ul className="ml-12">
          <li><b>12.1</b> El Usuario reconoce que toda la información contenida en la Plataforma MeRent, así como aquella que MeRent podrá entregarle, ya sea en forma escrita, electrónica o verbal, es información cuyo contenido los Usuarios acuerdan proteger con el carácter de confidencial (la "Información Confidencial"). La Información Confidencial comprenderá, en forma enunciativa más no limitativa, correspondencia, información técnica, información de terceros y/o otros usuarios de la Plataforma MeRent, información comercial relativa a la organización y actividades del Sitio, conocimientos técnicos y contractuales de MeRent. Asimismo, los Usuarios acuerdan y reconocen que dentro de la Información Confidencial que le será entregada por MeRent, podrán existir secretos industriales, entendiendo por éstos toda aquella información propiedad de MeRent de aplicación industrial y comercial que le permite obtener y mantener, entre otras cosas, ventajas económicas y de mercado frente a sus competidores. </li>
          <li><b>12.2</b> Los Usuarios están de acuerdo y aceptan conservar la Información Confidencial en estricta confidencialidad, y en este acto se obligan a no vender, divulgar, transferir, modificar, traducir, reproducir ni poner de otra forma Información Confidencial a disposición de terceros. Los Usuarios se obligan a poner el cuidado necesario en la protección de la Información Confidencial. Por otra parte, los Usuarios acuerdan y reconocen que las obligaciones de confidencialidad estarán vigentes durante todo el tiempo en que la Información Confidencial conserve dicho carácter. Asimismo, los Usuarios reconocen que la divulgación no autorizada de la Información Confidencial es castigada por la Ley de la Propiedad Industrial e incluso puede constituir la comisión de un delito.</li>
          </ul>
          <h4 className="font-bold">13. Propiedad intelectual.</h4>
          <ul className="ml-12">
          <li><b>13.1</b> El Sitio, los logotipos y todo el material que aparece en el Sitio, son marcas, nombres de dominio, nombres comerciales y obras artísticas propiedad de MeRent y/o sus respectivos titulares y están protegidos por los tratados internacionales y las leyes aplicables en materia de propiedad intelectual y derechos de autor. Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones, y en general, cualquier información contenida o publicada en el Sitio se encuentran debidamente protegidos a favor de MeRent, sus afiliados, proveedores y/o de sus respectivos propietarios, de conformidad con la legislación aplicable en materia de propiedad intelectual e industrial.</li>
          <li><b>13.2</b> Los Usuarios sólo podrán imprimir y/o copiar cualquier información contenida o publicada en el Sitio exclusivamente para uso personal, quedando terminantemente prohibido el uso comercial de dicha información. En caso de ser persona moral se sujetará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor. La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por otro medio, parcial o total, de cualquier información, documento o gráfico que aparezca en el Sitio, para cualquier uso distinto al personal no comercial le está expresamente prohibido a los Usuarios.</li>
          <li><b>13.3</b> Los Usuarios reconocen que, al proporcionar la información de carácter personal requerida en alguno de los servicios que se prestan en este Sitio, otorgan a MeRent la autorización señalada en el artículo 109 de la Ley Federal del Derecho de Autor.</li>
          <li><b>13.4</b> Se prohíbe expresamente a los Usuarios modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la información contenida en el Sitio. Por otra parte, el Usuario asume en este acto la obligación de notificar a MeRent, sobre cualquier uso o violación de parte de terceros de las marcas o cualesquiera otros derechos de propiedad industrial o intelectual propiedad de MeRent, tan pronto como los Usuarios tengan conocimiento de dichas violaciones o usos indebidos. Lo anterior, en el claro entendido que los Usuarios únicamente deberá notificar cualquier violación a los derechos de propiedad industrial o intelectual de MeRent, sin que le esté permitido defender o en cualquier forma actuar en nombre o representación de MeRent en la defensa de sus derechos de propiedad industrial o intelectual.</li>
          <li><b>13.5</b> En caso de que los Usuarios transmitan a MeRent cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través del Sitio, los Usuarios otorgan a MeRent una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente. Lo establecido en el presente párrafo se aplicará igualmente a cualquier otra información que los Usuarios envíen o transmitan a MeRent por cualquier medio.</li>
          <li><b>13.6</b> En caso de que algún Usuario o tercero consideren que cualquiera de los contenidos que se encuentren o sean introducidos en dicho Sitio y/o cualquiera de sus servicios, violen sus derechos de propiedad intelectual deberán enviar una notificación por escrito a MeRent.</li>
          </ul>
          <h4 className="font-bold">14. Declaraciones adicionales del Firmante y Usuario.</h4>
          <ul  className="ml-12">
          <li>El alta de un Usuario y/o el uso de la Firma Autógrafa Digitalizada o la Firma Electrónica Avanzada, implican las siguientes declaraciones y manifestaciones de voluntad del Firmante y del Usuario, en adición a cualesquiera otras que se desprendan conforme a lo indicado en los presentes Términos y Condiciones:</li>
          <li><b>14.1</b> En caso de actuar en nombre propio, como Usuario persona física, el Firmante declara ser mayor de edad y tener plena capacidad para darse de alta como Usuario y para otorgar cualquier otro acto a través del Sitio;</li>
          <li><b>14.2</b> En caso de actuar en representación de un Usuario persona moral, el Firmante declara que su representada es una persona moral debidamente constituida y existente de conformidad con la legislación que la rige, con capacidad para darse de alta como Usuario y para otorgar cualquier otro acto a través del Sitio conforme a su objeto social;</li>
          <li><b>14.3</b> En caso de actuar en representación de un Usuario persona moral, cuenta con las facultades suficientes para representar al Usuario, las cuales no le han sido modificadas, revocadas, ni limitadas y que se mantendrán subsistentes en los mismos términos mientras el Usuario no notifique a MeRent lo contrario;</li>
          <li><b>14.4</b> La información capturada en el Sitio, relativa a la identidad, domicilio, datos de contacto, datos de cuentas bancarias y otros datos del Usuario, es veraz, completa y correcta;</li>
          <li><b>14.5</b> Los documentos con los que el Firmante acredite la información que proporcione a través del Sitio, son y serán igualmente veraces, completos y correctos y que las copias de estos que se compartan en el Sitio son y serán reproducciones fieles y exactas de sus originales;</li>
          <li><b>14.6</b> En caso de que alguna acción u omisión de los Usuarios genere algún daño, molestia o perjuicio a MeRent, el Usuario responsable se obliga a indemnizarla y a dejarla en paz y a salvo, sin costo para ella, incluyendo el reembolso de gastos legales y honorarios de abogados, si fuera necesaria su contratación para defender los intereses de MeRent o de otros Usuarios que MeRent represente, con motivo de cualquier incumplimiento o contravención a las disposiciones legales o convencionales a las que el Usuario se encuentre sometido, lo anterior si así es determinado por autoridad judicial;</li>
          <li><b>14.7</b> El Usuario está enterado de que MeRent se reserva el derecho de bloquear el acceso o remover en forma parcial o total toda información, comunicación o material que a su exclusivo juicio pueda resultar:</li>
          <li className="ml-4"><b>a.</b> Abusivo, difamatorio u obsceno;</li>
          <li className="ml-4"><b>b.</b> Fraudulento, artificioso o engañoso;</li>
          <li className="ml-4"><b>c.</b> Violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de un tercero;</li>
          <li className="ml-4"><b>d.</b> Ofensivo;</li>
          <li className="ml-4"><b>e.</b> Que de cualquier forma contravenga lo establecido en los presentes Términos y Condiciones de uso de la Plataforma;</li>
          <li><b>14.8</b> El Firmante está consciente de que él es el único responsable por el uso de su cuenta de correo electrónico y por el manejo de los datos de nombre de usuario y contraseña para el acceso a la Plataforma MeRent por el Usuario. MeRent no asume responsabilidad por el uso no autorizado por parte de terceros que utilicen la cuenta de correo electrónico del Firmante, o sus datos para el ingreso a la Plataforma MeRent;</li>
          <li><b>14.9</b> Cada Usuario es responsable de adquirir y dar mantenimiento al equipo informático y software que se requiera para la navegación en el Sitio y para el acceso a la Plataforma MeRent. MeRent no está obligada a suministrar ningún tipo de equipo ni software, así como tampoco el mantenimiento o soporte técnico correspondiente, por lo no asume ningún tipo de responsabilidad al respecto;</li>
          <li><b>14.10</b> El uso de la Plataforma MeRent es responsabilidad y corre por cuenta y riesgo de cada Usuario, no siendo imputable a MeRent ningún tipo de responsabilidad al respecto, incluyendo sin limitación, por daños, perjuicios y gastos directos o indirectos que surjan en relación con el uso de la Plataforma MeRent, su contenido o por la imposibilidad de uso, así como en caso de falla en el rendimiento de la Plataforma MeRent y/o en cualquier otro implemento que se requiera para la navegación en el Sitio;</li>
          <li><b>14.11</b> El Sitio y la Plataforma MeRent podrán dejar de estar disponibles temporalmente por mantenimiento u otras razones. El Usuario libera a MeRent de toda responsabilidad por cualquier error, omisión, interrupción, supresión, defecto, demora en la operación o transmisión, falla en la línea de comunicación, robo, destrucción de datos o acceso no autorizado a ellos, ni por alteración de las comunicaciones de los Usuarios;</li>
          <li><b>14.12</b> El Usuario libera a MeRent de toda responsabilidad por cualquier problema técnico o mal funcionamiento de cualquier red o líneas telefónicas del Usuario, sistemas informáticos en red o fuera de red, o servidores, así como tampoco por cuestiones técnicas de equipos de los Usuarios ocasionadas por problemas de tráfico de internet y/o en la Plataforma MeRent o por virus informáticos, incluyendo sin limitar, descomposturas en la computadora o dispositivos mediante los que el Usuario acceda a la Plataforma MeRent;</li>
          <li><b>14.13</b> El Usuario libera a MeRent de toda pérdida o daño de la información del propio Usuario, ocasionada por el uso de la Plataforma MeRent o por el acceso a cualquier contenido de terceros publicado en el Sitio o al que se direccione a través del Sitio;</li>
          <li><b>14.14</b> El Usuario está consciente de que el Sitio y todo su contenido pueden contener enlaces a sitios externos de terceros en los que MeRent no ejerce control alguno. Las consecuencias derivadas del acceso a tales sitios externos son responsabilidad exclusiva del propio Usuario (incluyendo infecciones en los equipos del Usuario por virus informáticos o que su contenido resulte inapropiado). Asimismo, el Usuario libera a MeRent de cualquier responsabilidad por: (i) la falta de exactitud, de veracidad, de utilidad o de adecuación para propósitos determinados; y (ii) el contenido de los sitios externos a los que se pueda acceder a través del Sitio. Cualquier reclamo que el Usuario llegare a tener con motivo del acceso o navegación por cualquier sitio externo, deberá ser dirigido directamente al administrador del sitio externo de que se trate;</li>
          <li><b>14.15</b> En caso de que existan o se presenten fallas en el Sitio, MeRent deberá de hacerlo del conocimiento del Usuario a través del correo electrónico y/o el número telefónico proporcionado por el mismo en la propia Plataforma MeRent, indicando los medios para continuar con el servicio proporcionado por MeRent;</li>
          <li><b>14.16</b> Las operaciones a través de la plataforma estarán disponibles 24 horas y el Usuario podrá contactar de lunes a viernes de 09:00 a 18:00 al personal de MeRent, para comentarios, dudas o sugerencias.</li>
          </ul>
          <h4 className="font-bold">15. Claves de Acceso, Robo, Extravío u Olvido.</h4>
          <ul className="ml-12">
          <li><b>15.1</b> Los Usuarios crean, con su correo electrónico, una cuenta de usuario personal, exclusiva e intransferible para realizar los procesos en la Plataforma MeRent y celebrar operaciones mediante el uso de su firma electrónica.</li>
          <li><b>15.2</b> El uso de la Cuenta de Usuario es personal e intransferible, por lo cual los Usuarios no se encuentran facultados para ceder los datos de validación para el acceso a su perfil a terceras personas. En caso de olvido de los datos de validación o de usurpación de estos, es obligación del Usuario informar a MeRent a través de los medios que este último ponga a su disposición ante dicha situación para que se puedan recuperar los datos de validación correspondientes, en los términos de los presentes Términos y Condiciones de uso de la Plataforma.</li>
          <li><b>15.3</b> Es la entera responsabilidad del Usuario mantener de forma confidencial y en un lugar seguro su usuario y contraseña para acceder a su perfil en la Plataforma MeRent, por lo que, mediante el presente instrumento, el Usuario deslinda a MeRent de toda responsabilidad por la pérdida o daño alguno, sin importar su alcance o naturaleza, que resulte del acceso no autorizado al perfil del Usuario.</li>
          </ul>
          <h4 className="font-bold">16. Mecanismos y procedimientos para el bloqueo y restablecimiento de cuentas.</h4>
          <ul className="ml-12">
          <li><b>16.1</b> La cuenta que tengan dada de alta los Usuarios, en la Plataforma MeRent, podrá ser bloqueada temporalmente por MeRent, por las siguientes situaciones:</li>
          <li className="ml-4"><b>1.</b> Cuando se intente ingresar a la Plataforma MeRent, con credenciales de usuario y/o contraseñas erróneas, más de 3 veces seguidas. Una vez que el Usuario supere el número de intentos fallidos, antes señalados, la cuenta del Usuario se bloqueará de forma automática y deberá de restablecer la contraseña de acuerdo con el procedimiento de restablecimiento de cuentas de usuario, establecido en la Plataforma MeRent;</li>
          <li className="ml-4"><b>2.</b> Cuando se detecte que el último registro y/o inicio de sesión de un Usuario es mayor a 6 meses, entonces, la Plataforma MeRent, inhabilitará de forma automática la cuenta del Usuario, la cual podrá ser activada nuevamente de conformidad con los procedimientos establecidos en la Plataforma MeRent.</li>
          <br></br>
          <li><b>16.2</b> Una contraseña bloqueada u olvidada por un Usuario puede ser restablecida ingresando a la siguiente dirección electrónica www.merent.mx y dando clic en el enlace descrito como "¿Olvidaste tu contraseña?", el cual solicitará únicamente el correo electrónico con el que el Usuario registró con anterioridad en la propia Plataforma MeRent. </li>
          <li><b>16.3</b> Una vez realizado lo anterior, se enviará al correo electrónico registrado por el Usuario en la Plataforma MeRent: </li>
          <li><b>16.4</b> Un correo electrónico con un enlace para modificar la contraseña bloqueada y/o olvidada. Dicho enlace contiene un token de 64 caracteres alfanuméricos aleatorios los cuales son almacenados en la base de datos de manera cifrada. Esto asegura que ningún usuario o administrador de la Plataforma MeRent podrá obtener dicho token a partir de ese valor. Una vez que dicho token es usado exitosamente, este es borrado de la base de datos no podrá ser usado nuevamente.</li>
          <li><b>16.5</b> Un correo electrónico con el segundo factor de autenticación para autorizar la operación.</li>
          <li><b>16.6</b> Dado que el bloqueo o restablecimiento de la contraseña de la cuenta del Usuario, no puede ser realizado por un usuario administrador, el usuario es el único facultado en realizar dicha acción mediante el envío del correo electrónico relacionado a su cuenta e ingresando el segundo factor de autenticación.</li>
          <li><b>16.7</b> El Usuario debe de ser el único dueño del correo electrónico al que se envían los factores de autentificación antes descritos, para realizar el proceso establecido en este apartado, en virtud de lo anterior, el ingreso del Usuario al correo electrónico dado de alta en la Plataforma MeRent, es indispensable para el desbloqueo de su cuenta.</li>
          <li><b>16.8</b> Dado que la contraseña no se almacena en texto plano, sino que se cifra utilizando el algoritmo bcrypt, lo cual implica que no es posible obtener nuevamente la contraseña original a partir de dicho valor. En ningún momento los administradores de MeRent pueden restablecer la contraseña de ningún Usuario.</li>
          <li><b>16.9</b> Para dar seguimiento a este proceso, se utilizan los siguientes canales de comunicación oficial con MeRent: </li>
          <li className="ml-4"><b>{"->"}</b> El número telefónico dado de alta por el Usuario en la Plataforma MeRent; </li>
          <li className="ml-4"><b>{"->"}</b> El correo electrónico dado de alta por el Usuario en la Plataforma MeRent; y</li>
          <li className="ml-4"><b>{"->"}</b> Algún sistema de mensajería electrónica habilitado por MeRent, y aceptado por el Usuario.</li>
          <br></br>
          <li ><b>16.10</b>En caso de que el bloqueo o inhabilitación sea a través de un requerimiento de autoridad competente, o sea derivado de una situación o detección de actividad inusual, MeRent podrá solicitar documentación adicional al Usuario, la cual será determinada según el caso específico.</li>
          </ul>
          <h4 className="font-bold">17. Aviso de Privacidad.</h4>
          <ul className="ml-12">
          <li>Con la finalidad de dar cumplimiento a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, MeRent informa a los Visitantes y Usuarios que podrán consultar y aceptar el aviso de privacidad de MeRent, que se encuentra disponible en el siguiente enlace: www.merent.mx</li>
          </ul>
          <h4 className="font-bold">18. Modificaciones.</h4>
          MeRent tendrá el derecho de modificar en cualquier momento los Términos y Condiciones para la Plataforma MeRent mediante simple publicación de los nuevos términos y condiciones en el Sitio, en el entendido de que en el caso de que el Usuario no esté de acuerdo con dichas modificaciones, deberá abstenerse de tener acceso al mismo.
          <h4 className="font-bold">19. Leyes Aplicables y Jurisdicción.</h4>
          Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones para la Plataforma MeRent, las partes se someten a la jurisdicción de los tribunales competentes de la Ciudad de México, México, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros.
          <h4 className="font-bold">20. Términos y Condiciones de Uso de la Plataforma de fecha 31 de diciembre  de 2024.</h4>
          </p>
      </div>
    </Container>
  </Layout>
)


export default TerminosCondiciones;
